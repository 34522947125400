@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #F56200;
}

::-webkit-scrollbar {
  /*width: 7px;
  height: 7px;*/
  display: none;
}

::-webkit-scrollbar-thumb {
  /*margin: 4px;
  border-radius: 99px;
  background-color: var(--primary);*/
  display: none;
}

@font-face {
  font-family: 'SansationBold';
  src: url('./sansation-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Baloo 2';
  src: url('./Baloo2-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'SansationBold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.back{

  background: rgba(255, 223, 202, 0.2);
  opacity: 0.5;
  border: 1px solid #FFDFCA;
  backdrop-filter: blur(22px);

}

.background-image {
  position: absolute;
  background: linear-gradient(180deg,#F56200 100%, #F56200 0%, #F56200 100%);
}

.background-image::before {
  content: "";
  position: absolute;
  top: 0;
  pointer-events: none;
  right: 0;
  bottom: 0;
  left: 0;
  background: 
    linear-gradient(180deg, rgba(245, 98, 0, 0.5), rgba(245, 98, 0, 0.5)),
    url('../assets/bckgrnd.png') no-repeat center center fixed;
  background-size: auto 200%, cover;
  opacity: 0.08;
}


.font-primary {
  font-family: 'SansationBold';
}

.font-secondary {
  font-family: 'Baloo 2', cursive;
}

.font-review {
  font-family: 'Baloo 2';
}

.figtree-font {
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}
.figtree-font-bold {
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}


.bg-primary {
  background-color: var(--primary);
}

.text-primary {
  color: var(--primary);
}

.success-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vw;
  border-radius: 50%;
  background-color: var(--primary);
  transform: translate(-50%,-50%) scale(0);
  animation: success_bg_anim 1.5s 0.8s forwards;
  z-index: -1;
}

.success-logo-primary {
  position: absolute;
  top: 0;
  left: 0;
  animation: logo_anim 1.5s forwards;
}

.success-banner {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  animation: fade_up_anim 1s 1.2s forwards;
}

.not-found-modal {
  text-align: center;
}

.not-found-modal h1 {
  font-size: 128px;
  letter-spacing: 8px;
  margin: 0;
  text-shadow: 0 12px 12px rgba(0,0,0,0.1);
  color: var(--primary);
  animation: scale_anim .5s;
}

.fade {
  animation: fade_anim 1s;
}

.fade-up {
  animation: fade_up_anim 1s;
}

.fade-down {
  animation: fade_down_anim 1s;
}

.fade-right {
  animation: fade_right_anim 1s;
}

.fade-left {
  animation: fade_left_anim 1s;
}

.pongpong-anim {
  animation: pongpong_anim 1s;
}

@keyframes popup_anim {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes pongpong_anim {
  0% { transform: scale(0); }
  20% { transform: scale(1.05); }
  40% { transform: scale(0.95); }
  70% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

@keyframes fade_anim {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade_up_anim {
  from { transform: translateY(64px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fade_down_anim {
  from { transform: translateY(-64px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fade_right_anim {
  from { transform: translateX(-64px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fade_left_anim {
  from { transform: translateX(64px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes logo_anim {
  0% { transform: rotateZ(-720deg) scale(0); }
  35% { transform: rotateZ(0deg) scale(1.5) }
  50% { transform: rotateZ(0deg) scale(1.5); }
  70% { transform: rotateZ(0deg) scale(1); opacity: 1; }
  100% { transform: rotateZ(0deg) scale(1); opacity: 0; }
}

@keyframes success_bg_anim {
  from { transform: translate(-50%,-50%) scale(0); }
  to { transform: translate(-50%,-50%) scale(1.5); }
}

@keyframes scale_anim {
  from { transform: scale(0); }
  to { transform: scale(1); }
}